.video-container {
    background: #000;
    overflow: hidden;
}

.video-16-9 {
    aspect-ratio: 16 / 9;
}

.video-container iframe {
    width: 100%;
    height: 100%;
}
