//image
img {
    width: 100%;
    max-width: 100%;
    display: block;
}

.bg-image-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-image-contain {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.bg-image {
    background-repeat: no-repeat;
    background-position: center;
}

.breakout-image {
    @include media-breakpoint-down(md) {
        left: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        position: relative;
        right: 50%;
        width: 100vw !important;
    }
}


