body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'pantonregular', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: $color-font;
    font-size: 21px;
    @include media-breakpoint-up(lg) {
        font-size: 20px;
    }
    letter-spacing: 0.1em;
    //letter-spacing: 2px;

    /*
    mobile:
    Lead-Introtext 19pt => 25,333333
    body: 16pt >= 21,33333px
    legend: 14pt => 18,6666px

    desktop:
    Lead-Introtext 26px
    body: 20px
    legend: 16px
    */

    b, strong, .strong {
        font-family: 'pantonbold', sans-serif;
        font-weight: 900;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    a, .link {
        color: $color-red;
        text-decoration: none;
    }
    a:hover, .link:hover {
        color: $color-red;
        text-decoration: none;
    }

    p {
        max-width: 850px;
    }

    h1, .h1,
    h2, .h2,
    .lead, .intro {
        font-size: 25px;

        font-size: 23px;
        line-height: 130%;

        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }
    h3, .h3,
    h4, .h4 {
        font-size: 21px;
        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }
    }
    .lead {
        max-width: 850px;
        line-height: 1.6;
    }

    .text-small, .legende {
        font-size: 19px;
        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }
    }

    .color-font-light {
        color: $color-font-light;
    }

    .hyphens {
        hyphens: auto;
    }

    .container-text ul {
        margin-bottom: 2rem;
    }
    .container-text li {
        line-height: 1.8rem;
        margin-bottom: 0.7rem;
    }

}
