body.show-indextext {
    #indextext {
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            height: auto;
        }
    }

    #main {
        position: relative;
        transition: all 0.5s ease-in-out;

        &.is-blurred:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(8px);
            -webkit-backdrop-filter: blur(8px);
            z-index: 100;
        }
    }
}

body {
    #indextext {
        transition: height 0.6s ease-in-out;
        height: 0;
        overflow: hidden;
        .row-text {
            overflow: scroll;
            @include media-breakpoint-up(lg) {
                overflow: hidden;
            }
            p {
                font-size: 19px;
                @include media-breakpoint-up(lg) {
                    font-size: 19px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .arrow-up {
            cursor: pointer;
        }
    }
}


#cookieAlert {
    bottom: 32px;
    @include media-breakpoint-up(lg) {
        bottom: 48px;
    }
    background: white;
    border: 1px solid $color-red;
    border-radius: 8px;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.75);
    font-size: 14px;
    line-height: 1.5;
}

