.hero-container {
    height: calc(70vh - 90px);
    //max-width: 2500px;

    h2 {
        font-size: 23px;

        @include media-breakpoint-up(lg) {
            font-size: 35px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 40px;
        }
    }
}
