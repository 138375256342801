.container-fluid {
    max-width: 1920px;
}
.container-image-text,
.container-text {
    ul li {
        list-style-type: '— ';
    }
}

@include media-breakpoint-down(lg) {
    .container-image-text .image-block {
        order: 0 !important;
    }
}

.container-header {
    .list-metadata {
        line-height: 150%;
        @include media-breakpoint-up(lg) {
            // line-height: 26px;
        }
    }
}

.weiter {
    display: none;
}
@include media-breakpoint-down(lg) {
    .lead5lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    .weiter {
        color: $color-red;
        margin-top: -20px;
        cursor: pointer;
    }
    .lead5lines + .weiter {
        display: block;
    }
}


.fui-alert.fui-alert-success {
    background-color: white;
    font-family: 'pantonregular', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: $color-font;
    font-size: 21px;
    @include media-breakpoint-up(lg) {
        font-size: 20px;
    }
    letter-spacing: 0.1em;
}
