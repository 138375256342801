@use 'sass:map';

// Variables
@use './utils/svg';

// bootstrap shade-color function
@function shade-color($color, $weight) {
    @return mix(black, $color, $weight);
}

// Color system
$primary:   hsl(50deg 87% 60%);
$secondary: hsl(15deg 64% 52%);
$success:   hsl(89deg 56% 62%);
$info:      hsl(204deg 97% 64%);
$warning:   hsl(27deg 91% 45%);
$danger:    hsl(12deg 100% 37%);
$light:     hsl(0deg 0% 87%);
$dark:      hsl(0deg 0% 15%);

:root {
    --primary:   #{$primary};
    --secondary: #{$secondary};
    --success:   #{$success};
    --info:      #{$info};
    --warning:   #{$warning};
    --danger:    #{$danger};
    --light:     #{$light};
    --dark:      #{$dark};
}

$theme-colors: (
    'primary':   $primary,
    'secondary': $secondary,
    'success':   $success,
    'info':      $info,
    'warning':   $warning,
    'danger':    $danger,
    'light':     $light,
    'dark':      $dark
);

// Auto calculation contrast ratio setting
$min-contrast-ratio:   4.5;

// Add custom colors to theme-colors
$custom-theme-colors: (
    'red': #BF002C,
    'font': #383836,
    'font-light': #8B8B87
);

// Save as array
$theme-colors: map.merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

$grid-columns:          12;
$grid-gutter-width:     1rem;

$grid-breakpoints: (
    xs: 0,
    sm: 520px,
    md: 768px,
    lg: 1024px,
    xl: 1216px,
    xxl: 1513px
);
// Spacings
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 4.5,
    7: $spacer * 6,
    12: $spacer * 2 / 8 * 3,
    32: $spacer * 2,
) !default;

// Fix spacings
$blockquote-margin-y:       map.get($spacers, 4);
$hr-margin-y:               map.get($spacers, 4);
$dropdown-item-padding-y:   map.get($spacers, 1);
$dropdown-item-padding-x:   map.get($spacers, 4);
$card-spacer-y:             map.get($spacers, 4);
$card-spacer-x:             map.get($spacers, 4);
$card-img-overlay-padding:  map.get($spacers, 4);
$popover-header-padding-x:  map.get($spacers, 4);
$popover-body-padding-y:    map.get($spacers, 4);
$popover-body-padding-x:    map.get($spacers, 4);
$modal-inner-padding:       map.get($spacers, 4);
$alert-padding-y:           map.get($spacers, 4);
$alert-padding-x:           map.get($spacers, 4);
$list-group-item-padding-y: map.get($spacers, 2);
$list-group-item-padding-x: map.get($spacers, 4);

// Font sizes (Reset). Use "fs-X" to set font size!
$font-size-base:              1.2rem;
$lead-font-size:              $font-size-base * 1.5;
$lead-font-weight:            300;

$h1-font-size: $font-size-base * 4;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2.5;
$h4-font-size: $font-size-base * 2;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base * 1;

//DISPLAY FONT SIZES
$display-font-sizes: (
    1: 5.5rem,
    2: 5rem,
    3: 4.5rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem
);
// General settings
$enable-rounded: false;
$enable-negative-margins: true;
//$link-color:                        $primary;
//$link-hover-color:                  shade-color($primary, 20%);

// Accordion
$accordion-button-icon:         svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
$accordion-button-active-icon:  svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");

// Modal
$modal-backdrop-opacity: .75;

$color-red: rgba(191, 0, 44, 1);
$color-red-10: rgba(191, 0, 44, 0.1);
$color-font: #383836;
$color-font-light: #8B8B87;
$color-bg-dark: #101010;
$color-grey: #707070;

