footer {
    background-color: $color-bg-dark;

    a, .link {
        color: white;
    }

    a, .link, svg {
        color: white;
        fill: white;
        width: 22px;
        height: 22px;
    }

    a:hover {
        color: white;
    }

    hr {
        color: $color-grey;
        opacity: 1;
    }

    @include media-breakpoint-up(xl) {
        .col-servicenavigation {
            border-left: 1px solid $color-grey;
        }
    }

    a.totop,
    svg.icon-up {
        width: 68px;
        height: 47px;
    }

}
