.lh-0 {
    line-height: 0;
}
nav {
    height: 90px;
    max-height: 90px;
    @include media-breakpoint-up(lg) {
        height: 112px;
        max-height: 112px;
        // width: 100vw;
    }
    overflow: hidden;

    a:hover {
        color: black;
    }

    .logo {
        width: 770px;
        height: 45.69px;
        max-width: 100%;
    }

    .extra-navi {
        svg {
            width: 9.38px;
            height: 15px;
            fill: currentColor;
        }
    }

    .navbar-toggler {
        position: relative;
        height: 32px;
        width: 38px;
        span {
            left:0;
            position: absolute;
            height: 4px;
            width: 100%;
            background-color: $color-red;
            transition: all 0.5s ease-in-out;
        }
        .line-1, .line-2, .line-3 {
            top:14px;
        }
    }
    .navbar-toggler.collapsed {
        span {
            background-color: $color-font-light;
        }
        .line-1 {
            top:0;
        }
        .line-3 {
            top:28px;
        }
    }
    .navbar-toggler:focus {
        box-shadow: none;
    }

    #navigation {

        //backdrop-filter: blur(5px);
        //background-color: rgba(255, 255, 255, 0.9);
        background-color: rgb(255, 255, 255);
        position: absolute;
        width: 100%;
        z-index: 10;


        @include media-breakpoint-down(lg) {
            height: calc(100% - 90px);
            margin-top: 90px;
            overflow-y: scroll;
            top: 0;

            .container-navigation .nav-group:first-of-type h2 {
                margin-top: 1rem !important;
            }
        }
    }

}
@include media-breakpoint-down(lg) {

    .container-language-switch {
        height: 36px;
        .col-6 {
            border: 1px solid $color-font-light;
        }
        .col-6:last-of-type {
            border-left: 0;
        }
    }
    body.body-homepage.position-fixed {
        .container-language-switch {
            display: block;
        }
    }

}
.collapsing {
    transition: height 0.45s cubic-bezier(0.44, 0.41, 0.52, 0.65);
}
