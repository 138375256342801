body.body-homepage {
    main {
        height: calc(100vh - 90px);
        @include media-breakpoint-up(lg) {
            height: calc(100vh - 112px);
        }

        .cat-wrapper {
            @include media-breakpoint-down(lg) {
                overflow: hidden;
                background-size: 100%;
                height: calc(100% / 4);
                display: flex;
                align-items: center;
                div {
                    overflow-x: hidden ;
                    .chevron-right {
                        height: 20px;
                        margin-left: 16px;
                        width: 12.5px;
                    }
                }
            }
            
            div  {
                display: flex;
                background-color: white;
                @include media-breakpoint-up(lg) {
                    opacity: 0.5;
                    .chevron-right {
                        margin-left: 16px;
                        width: 37.5px;
                        height: 60px;
                    }
                }
                a, h2 {
                    color: black;
                    @include media-breakpoint-up(lg) {
                        font-size: 70px;
                    }
                }
            }
        }

    }

    @include media-breakpoint-up(lg) {
        main {
            flex-wrap: nowrap;
            .cat-wrapper {
                background-size: cover;
                height: calc(100vh - 112px);
                width: 100%;
                overflow: hidden;
                @media screen and (prefers-reduced-motion: no-preference) {
                    transition: all 0.8s ease-in-out;
                }
            }
            .cat-wrapper:hover {
                width: 300%;
                div {
                    opacity: 1;
                }
            }
        }
    }
}

/* Set the size of the div element that contains the map */
#map {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
}
