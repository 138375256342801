@font-face {
    font-family: 'pantonregular';
    src: url('../../fonts/Panton-Regular-WebFont/panton-regular-webfont.eot');
    src: url('../../fonts/Panton-Regular-WebFont/panton-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Panton-Regular-WebFont/panton-regular-webfont.woff2') format('woff2'),
    url('../../fonts/Panton-Regular-WebFont/panton-regular-webfont.woff') format('woff'),
    url('../../fonts/Panton-Regular-WebFont/panton-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pantonbold';
    src: url('../../fonts/Panton-Bold-WebFont/panton-bold-webfont.eot');
    src: url('../../fonts/Panton-Bold-WebFont/panton-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Panton-Bold-WebFont/panton-bold-webfont.woff2') format('woff2'),
    url('../../fonts/Panton-Bold-WebFont/panton-bold-webfont.woff') format('woff'),
    url('../../fonts/Panton-Bold-WebFont/panton-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
