.container-contactform {

    form .fui-row {

        .fui-field-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $color-font-light;
        }
        .fui-field-required .fui-field-container {
            border-bottom: 1px solid $color-red;
        }
        .fui-type-multi-line-text {

            margin-top: 16px;

            .fui-field-container {
                border: none;
                display: block;
                textarea {
                    border-radius: 0;
                    border: 1px solid $color-font-light;
                }
            }
        }

        .fui-type-multi-line-text.fui-field-required .fui-field-container {
            textarea {
                margin-top: 8px;
                border: 1px solid $color-red;
                height: 120px;
            }
        }
        
        label.fui-label {
            margin-bottom: 0;
            min-width: 120px;
            font-size: 21px;
            @include media-breakpoint-up(lg) {
                font-size: 20px;
            }
        }
        .fui-field-required label.fui-label {
            color: $color-red;
            font-weight: bold;
        }
        span.fui-required {
            color: $color-red;
            font-weight: bold;
        }
        .fui-input-container {
            flex-grow: 1!important;
        }
        .fui-input-container input,
        .fui-input-container textarea {
            border: none;
            border-radius: 0;
            box-shadow: none;
            font-size: 25px;
            @include media-breakpoint-up(lg) {
                font-size: 26px;
            }
        }
        .fui-input-container input.fui-input:focus {
            background-color: $color-red-10;
        }

    }

    .fui-btn-wrapper button.fui-submit {
        background-color: white;
        border: 1px solid $color-red;
        color: $color-font;
        font-size: 21px;
        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }
    }

    .fui-error-message {
        display: none;
    }

}
