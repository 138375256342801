.backbutton {
    position: relative;
    border: 1px solid $color-grey;
    color: $color-red;
    svg {
        transform: scale(-1, -1);
        height: 12px;
        width: 7.5px;
        margin-right:4px;
        fill: $color-red;
    }
    font-size: 18px;
}
.backbutton:hover {
    color: $color-red;
    svg {
        fill: $color-red;
    }
}
body .text-supersmall {
    font-size: 16px;
}
